.processing {
  background: #fff2cc;
  font-weight: 700;
  text-align: center;
}

.rejected {
  background: #f8cecc;
  font-weight: 700;
  text-align: center;
}

.accepted {
  background: #d5e8d4;
  font-weight: 700;
  text-align: center;
}

